import { Injectable } from "@angular/core";
import { Body, Delete, Get, Path, Post, RestService } from "ngx-rest-service";
import {
  BrandBlackList,
  BrandBlackListRequest,
  CategoryBlackList,
  GenericResponse,
  KeywordBlackListResponse,
  PageableResponse,
  RestrictedKeywordRequest,
} from "../models";
import { Observable } from "rxjs";
import { CreateCategoryBlackList } from "../models/restriction/create-category-black-list";
import { MarketplaceAsinsListRequest } from "../models/restriction/asin-black-list-request";
import { AsinBlackList } from "../models/restriction/asin-black-list";

@Injectable({
  providedIn: "root",
})
export class RestrictionService extends RestService {
  @Get("keyword-black-list/{marketplaceId}/{pageNumber}/{pageSize}")
  getKeywordBlackListByMarketPlaceId(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<KeywordBlackListResponse[]>> {
    return null;
  }

  @Get(
    "keyword-black-list/search/{marketplaceId}/{keywordName}/{pageNumber}/{pageSize}"
  )
  searchKeywordBlackListByMarketPlaceId(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Path("keywordName") keywordName: string
  ): Observable<PageableResponse<KeywordBlackListResponse[]>> {
    return null;
  }

  @Post("keyword-black-list")
  createKeywordBlackList(
    @Body request: RestrictedKeywordRequest
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Get("brand-black-list/restricted/{marketplaceId}/{pageNumber}/{pageSize}")
  getRestrictedBrandBlackList(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<BrandBlackList[]>> {
    return null;
  }

  @Get(
    "brand-black-list/restricted/search/{marketplaceId}/{brandName}/{pageNumber}/{pageSize}"
  )
  searchRestrictedBrandBlackList(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Path("brandName") brandName: string
  ): Observable<PageableResponse<BrandBlackList[]>> {
    return null;
  }

  @Get("brand-black-list/allowed/{marketplaceId}/{pageNumber}/{pageSize}")
  getAllowedBrandBlackList(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<BrandBlackList[]>> {
    return null;
  }

  @Get(
    "brand-black-list/allowed/search/{marketplaceId}/{brandName}/{pageNumber}/{pageSize}"
  )
  searchAllowedBrandBlackList(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Path("brandName") brandName: string
  ): Observable<PageableResponse<BrandBlackList[]>> {
    return null;
  }

  @Post("brand-black-list/allowed")
  createAllowedBrandBlackList(
    @Body request: BrandBlackListRequest
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Post("brand-black-list/restricted")
  createRestrictedBrandBlackList(
    @Body request: BrandBlackListRequest
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Post("marketplace-asins/list")
  createMarketplaceAsinsList(
    @Body request: MarketplaceAsinsListRequest
  ): Observable<PageableResponse<AsinBlackList[]>> {
    return null;
  }

  @Post("marketplace-asins/list")
  createMarketplaceAsinsNoActionList(
    @Body request: MarketplaceAsinsListRequest
  ): Observable<PageableResponse<AsinBlackList[]>> {
    return null;
  }

  @Get("categories-black-list/{marketplaceId}/{pageNumber}/{pageSize}")
  getCategoryBlackList(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<CategoryBlackList>> {
    return null;
  }

  @Get(
    "categories-black-list/search/{marketplaceId}/{categoryName}/{pageNumber}/{pageSize}"
  )
  searchCategoryBlackList(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Path("categoryName") categoryName: string
  ): Observable<PageableResponse<CategoryBlackList>> {
    return null;
  }

  @Post("categories-black-list")
  createCategoryBlackList(
    @Body request: CreateCategoryBlackList
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete("categories-black-list/delete/{marketplaceId}/{type}")
  deleteCategoryBlackListItems(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Delete("keyword-black-list/delete/{marketplaceId}/{type}")
  deleteKeywordBlackListItems(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Delete("brand-black-list/restricted/delete/{marketplaceId}/{type}")
  deleteRestrictedBrandBlackListItems(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Delete("brand-black-list/allowed/delete/{marketplaceId}/{type}")
  deleteAllowedBrandBlackListItems(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<any>> {
    return null;
  }
}
