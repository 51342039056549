import {
  AccountManagementStateModel,
  GenericResponse,
  MarketPlace,
  PriceTableModel,
  SubscriptionDetail,
} from "../models";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { AccountManagementService } from "../services";
import {
  GetAccountInformation,
  GetFilters,
  GetMarketPlaces,
  GetPricesTable,
  GetSubscriptionDetails,
  UpdatePricesTable,
} from "../actions";
import { tap } from "rxjs/operators";
import snq from "snq";
import { Router } from "@angular/router";
import { AccountService } from "src/app/account/account.service";
import { Sendemailconfirmmodel } from "./sendemailconfirmmodel";

@State<AccountManagementStateModel>({
  name: "AccountManagementState",
  defaults: {
    allMarketPlaces: null,
    accountInformation: null,
    subscriptionDetails: null,
    filters: null,
    priceTable: null,
  },
})
@Injectable()
export class AccountManagementState {
  constructor(
    private accountManagementService: AccountManagementService,
    private router: Router,
    private accountService: AccountService
  ) {}

  @Selector()
  static getMarketPlacesResponse({
    allMarketPlaces,
  }: AccountManagementStateModel): GenericResponse<MarketPlace[]> {
    return allMarketPlaces;
  }

  @Selector()
  static getSubscriptionDetails({
    subscriptionDetails,
  }: AccountManagementStateModel): SubscriptionDetail {
    return subscriptionDetails;
  }

  @Selector()
  static getFilters({ filters }: AccountManagementStateModel) {
    return filters;
  }

  @Selector()
  static prices({ priceTable }: AccountManagementStateModel): PriceTableModel {
    return priceTable;
  }

  @Action(GetMarketPlaces)
  getMarketPlaces({ patchState }: StateContext<AccountManagementStateModel>) {
    return this.accountManagementService.getMarketPlaces().pipe(
      tap((response) => {
        const res = response.body;
        const headers = response.headers;

        const searchLimit = headers.get('X-Search-Limit');
        const usedLimit = headers.get('X-Used-Limit')

        if (res && res.Result && res.Result.length) {
          if (!localStorage.getItem('selectedMarketPlaceId')) {
            localStorage.setItem('selectedMarketPlaceId', res.Result[0].marketplaceId);
          }
          if (!localStorage.getItem('selectedMarketPlaceCountry')) {
            localStorage.setItem('selectedMarketPlaceCountry', res.Result[0].marketplaceCountry);
          }
          if (!localStorage.getItem('selectedMarketPlaceName')) {
            localStorage.setItem('selectedMarketPlaceName', res.Result[0].marketplaceName);
          }
          if (!localStorage.getItem('searchLimit')) {
            localStorage.setItem('searchLimit', searchLimit);
          }
          if (!localStorage.getItem('usedLimit')) {
            localStorage.setItem('usedLimit', usedLimit);
          }
        }
        patchState({ allMarketPlaces: res });
      })
    );
  }

  @Action(GetAccountInformation)
  getAccountInformation({
    patchState,
  }: StateContext<AccountManagementStateModel>) {
    return this.accountManagementService.getAccountInformation().pipe(
      tap((res) => {
        if (res.Result.isVerified === false) {
          let d: Sendemailconfirmmodel = new Sendemailconfirmmodel();
          d.email = res.Result.email;
          const promise = this.accountService.emailGonder(d).toPromise();
          promise.then((d) => {});
          this.router.navigateByUrl("/invitation/emailconfirmationagain");
        }
        localStorage.setItem("accountName", res.Result.username);
        localStorage.setItem("accountEmail", res.Result.email);
        localStorage.setItem("isDropyRepricer", res.Result.isDropyRepricer);
        localStorage.setItem("daysLeft", res.Result.daysLeft);
        patchState({ accountInformation: snq(() => res.Result, null) });
      })
    );
  }

  @Action(GetSubscriptionDetails)
  getSubscriptionDetails({
    patchState,
  }: StateContext<AccountManagementStateModel>) {
    return this.accountManagementService.getSubscriptionDetails().pipe(
      tap((res) => {
        if (!!res?.Result?.packageName) {
          localStorage.setItem(
            "packageName",
            snq(() => res.Result.packageName, null)
          );
          patchState({ subscriptionDetails: snq(() => res.Result, null) });
        } else {
          this.router.navigateByUrl("/invitation/package-selection");
        }

        // Independent check for product and set totalProductCount in localStorage
      if (!!res?.Result?.products) {
        localStorage.setItem(
          "totalProductCount",
          snq(() => res.Result.products.toString(), null)
        );
      }

      })
    );
  }

  @Action(GetFilters)
  getFilters({ patchState }: StateContext<AccountManagementStateModel>) {
    const marketPlaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.accountManagementService.getFilters(marketPlaceId).pipe(
      tap((res) => {
        patchState({ filters: res.Result });
      })
    );
  }

  @Action(GetPricesTable)
  getPricesTable(
    { patchState }: StateContext<AccountManagementStateModel>,
    { payload }: GetPricesTable
  ) {
    const marketPlaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.accountManagementService.getPrices(marketPlaceId, payload).pipe(
      tap((res) => {
        patchState({ priceTable: res.Result });
      })
    );
  }

  @Action(UpdatePricesTable)
  updatePricesTable(
    { patchState }: StateContext<AccountManagementStateModel>,
    { payload, type }: UpdatePricesTable
  ) {
    const marketPlaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.accountManagementService
      .updatePrices(marketPlaceId, type.toString(), payload)
      .pipe(
        tap((res) => {
          patchState({ priceTable: res.Result });
        })
      );
  }
}
