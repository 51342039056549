import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
} from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { TranslationService } from "src/app/services/translate.service";
import { isActiveAccountGuard } from "src/app/shared/guards/isActiveAccount.guard";
import { isActiveAmazonAccessGuard } from "src/app/shared/guards/isActiveAmazonAccess.guard";

import { GenericResponse, MarketPlace } from "src/app/shared/models";
import { AccountManagementState } from "src/app/shared/states";

import { ROUTES } from "./sidebar-items";

declare const Waves: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.sass"],
})
export class SidebarComponent implements OnInit {
  public sidebarItems: any[];
  showMenu: string = "";
  showSubMenu: string = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  isUsernameBlurred = false;
  isUserEmailBlurred = false;
  isUserSubscriptionPackageBlurred = false;

  username = localStorage.getItem("accountName");
  email = localStorage.getItem("accountEmail");
  packageName = localStorage.getItem("packageName");
  marketPlace: any = [];
  isActiveAlertMessage: string = "";
  isActiveAmazonAlertMessage: string = "";
  isActiveSearchProductAlertMessage: string = "";

  isActive: any = true;
  isActiveAmazon: any = true;
  isActiveSearchProduct: any = true;

  subPackageAsinCount: number | null = null;
  acceptableAsinCount: number | null = null;
  totalProductCountStr: string | null = null;

  @Select(AccountManagementState.getMarketPlacesResponse)
  marketPlaces$: Observable<GenericResponse<MarketPlace[]>>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private translate: TranslationService,
    private isActiveAccountGuard: isActiveAccountGuard,
    private isActiveAmazonAccessGuard: isActiveAmazonAccessGuard
  ) {
    ROUTES[0].title = this.translate.instant("dashboard");
    ROUTES[1].title = this.translate.instant("search_products");
    ROUTES[2].title = this.translate.instant("dashboard_orders");
    ROUTES[3].title = this.translate.instant("best_sellers");
    ROUTES[4].title = this.translate.instant("inventory");
    ROUTES[4].submenu[0].title = this.translate.instant("inventory_my_store");
    ROUTES[4].submenu[1].title = this.translate.instant(
      "inventory_add_new_product"
    );
    ROUTES[4].submenu[2].title = this.translate.instant(
      "inventory_bulk_product_delete"
    );
    ROUTES[4].submenu[3].title = this.translate.instant(
      "dashboard_best_selling_products"
    );
    ROUTES[4].submenu[4].title = this.translate.instant("dashboard_categories");
    ROUTES[5].title = this.translate.instant("restrictions");
    ROUTES[5].submenu[0].title = this.translate.instant(
      "inventory_categories_black_list"
    );
    ROUTES[5].submenu[1].title = this.translate.instant(
      "restrictions_keyword_black_list"
    );

    ROUTES[5].submenu[2].title = this.translate.instant(
      "restrictions_brand_black_list"
    );

    ROUTES[5].submenu[3].title = this.translate.instant(
      "restrictions_asin_black_list"
    );

    ROUTES[6].title = this.translate.instant("my_account");

    ROUTES[6].submenu[0].title = this.translate.instant(
      "my_account_my_store_info"
    );
    ROUTES[6].submenu[1].title = this.translate.instant(
      "my_account_my_store_preference"
    );

    ROUTES[6].submenu[2].title = this.translate.instant("my_account_my_bills");
    ROUTES[6].submenu[3].title = this.translate.instant(
      "my_account_my_notification"
    );
    ROUTES[6].submenu[4].title = this.translate.instant(
      "my_account_my_filters"
    );
    ROUTES[6].submenu[5].title = this.translate.instant(
      "my_account_my_price_table"
    );

    ROUTES[6].submenu[6].title = this.translate.instant(
      "my_account_my_received_payments"
    );

    ROUTES[6].submenu[7].title = this.translate.instant(
      "my_account_my_amazon_access"
    );
    ROUTES[6].submenu[8].title = this.translate.instant(
      "my_account_my_browser_extension"
    );

    ROUTES[7].title = this.translate.instant(
      "order_detail_warehouse_dropy_house"
    );

    ROUTES[7].submenu[0].title = this.translate.instant("dropy_house_main");

    ROUTES[7].submenu[1].title = this.translate.instant("dropy_house_inbox");
    ROUTES[7].submenu[2].title = this.translate.instant("dropy_house_credit");
    ROUTES[7].submenu[3].title = this.translate.instant(
      "my_account_my_bills_bills_table_invoices"
    );
    ROUTES[7].submenu[4].title = this.translate.instant("dropy_house_profile");
    ROUTES[7].submenu[5].title = this.translate.instant("dropy_house_settings");
    ROUTES[7].submenu[6].title = this.translate.instant(
      "dropy_house_shipping_label"
    );

    ROUTES[8].title = this.translate.instant("reports");
    ROUTES[8].submenu[0].title = this.translate.instant(
      "reports_profit_report"
    );
    ROUTES[8].submenu[1].title = this.translate.instant("reports_sales_report");
    ROUTES[8].submenu[2].title = this.translate.instant(
      "reports_all_marketplace_report"
    );
    ROUTES[8].submenu[3].title = this.translate.instant(
      "reports_account_health_report"
    );
    ROUTES[8].submenu[4].title = this.translate.instant(
      "reports_account_health_report_negative_feedbacks"
    );

    ROUTES[9].title = this.translate.instant("help");

    this.isActiveAlertMessage = this.translate.instant("isActiveAlertMessage");
    this.isActiveAmazonAlertMessage = this.translate.instant(
      "isActiveAmazonAlertMessage"
    );
    this.isActiveSearchProductAlertMessage = this.translate.instant(
      "isActiveSearchProductAlertMessage"
    );
  }
  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }

  callMenuToggle(event: any, element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callSubMenuToggle(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
  }

  findTargetIds(data, id) {
    if (!data || !id) return null;
    return data.find((marketplace: any) => {
      if (marketplace?.marketplaceId == id) return true;
    });
  }

  async ngOnInit() {
    this.isUsernameBlurred = localStorage.getItem('blurUsername') === 'true';
    this.isUserEmailBlurred = localStorage.getItem('blurUserEmail') === 'true';
    this.isUserSubscriptionPackageBlurred = localStorage.getItem('blurUserSubscriptionPackage') === 'true';
    this.isActive = await this.isActiveAccountGuard.canActivate("", "");
    this.isActiveAmazon = await this.isActiveAmazonAccessGuard.canActivate("", "");


    this.subPackageAsinCount = parseInt(localStorage.getItem('subPackageAsinCount') || '0', 10);
    this.acceptableAsinCount = parseInt(localStorage.getItem('acceptableAsinCount') || '0', 10);
    this.totalProductCountStr = localStorage.getItem('totalProductCount');

    if (this.totalProductCountStr) {
      const totalProductCount = parseInt(this.totalProductCountStr, 10); // Parse totalProductCount

      if (this.acceptableAsinCount && totalProductCount && this.acceptableAsinCount <= totalProductCount) {
        this.isActiveSearchProduct = false;
      } 
    }

    if (!this.isActive || !this.isActiveAmazon) {
      ROUTES[1].disabled = true;
      ROUTES[3].disabled = true;
      ROUTES[4].submenu[1].disabled = true;
      ROUTES[4].submenu[2].disabled = true;
    } else if (!this.isActiveSearchProduct) {
      ROUTES[1].disabled = true;
      ROUTES[4].submenu[1].disabled = true;
    } else {
      ROUTES[1].disabled = false;
      ROUTES[3].disabled = false;
      ROUTES[4].submenu[1].disabled = false;
      ROUTES[4].submenu[2].disabled = false;
    }

    this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  initLeftSidebar() {
    var _this = this;
    //Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
    // //Set Waves
    // Waves.attach(".menu .list a", ["waves-block"]);
    // Waves.init();
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    var height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }

  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    let body = this.elementRef.nativeElement.closest("body");

    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    let body = this.elementRef.nativeElement.closest("body");

    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
}
